<template>
  <div class="load grid two-columns">
    <div class="grid-item">
      <InputNumber
          class="spacing"
          title="Scheibenlänge (m)"
          option="paneWidth"
          :step="1" />
      <InputNumber
          title="Abstand Zugpfosten (m)"
          option="spacingPost"
          :step="1" />
    </div>

    <div class="grid-item">
      <InputNumber
          class="spacing"
          title="Scheibenhöhe (m)"
          option="paneHeight"
          :step="1" />
      <InputNumber
          title="Scheibenbelastung (kN/m)"
          option="paneLoad"
          :step="1" />
    </div>
  </div>
</template>

<script>
import InputNumber from "@/components/InputNumber";
export default {
  name: "Load",
  components: {InputNumber},
  data() {
    return {
    }
  },
};
</script>

<style lang="scss">
.load {
  grid-column-gap: 32px;

  @media (max-width: $width-tablet) {
    grid-template-columns: 1fr !important;
    grid-row-gap: 24px;
  }

  .spacing {
    margin-bottom: 24px;
  }
}
</style>